import { PartnerLogos } from '@/content/types'
import { useLocale } from '@/hooks/useLocale'
import SwiperCarousel, {
  infiniteLoopSettings,
} from '@/organisms/SwiperCarousel'
import { attributes } from '../content/partner_logos.md'
import Image from 'next/image'
import SeparatorHeader from '@/atoms/SeparatorHeader'
import NextLink from 'next/link'
import Button from '@/atoms/Button'

const PartnersLogos = () => {
  const { translations } = useLocale<PartnerLogos>(attributes)
  const items = translations.logobox

  if (items.length === 0) {
    return <div></div>
  }

  return (
    <div className="flex flex-col gap-16">
      <SeparatorHeader title={translations.title} />
      <SwiperCarousel
        settings={infiniteLoopSettings}
        wrapperClassName="ease-linear"
        sliderClassName="w-56"
        className="flex justify-center"
        swiperClassName="p-0"
      >
        {items.map(({ image, alt_text }, i) => {
          return (
            <div className="w-56 h-[136px] overflow-hidden bg-white">
              <div className='h-full relative flex justify-center'>
                <Image key={i} src={image} alt={alt_text} width={136} height={136} />
              </div>
            </div>
          )
        })}
      </SwiperCarousel>
      <div className="text-center">
        <NextLink href="/contact">
          <Button variant='primary'>
            {translations.button}
          </Button>
        </NextLink>
      </div>
    </div>
  )
}

export default PartnersLogos
