import Link from '@/atoms/Link'

type Props = {
  title: string
  href: string
  children: React.ReactNode
}

const TitledImageLink = ({ title, href, children }: Props) => {
  return (
    <div className="flex-1 grid grid-rows-link-boxes">
      <div className="flex flex-col justify-center h-32 row-start-1 row-span-1">
        {title && title.trim() !== '' && <Link href={href}>{title}</Link>}
      </div>
      <div className="row-start-2 row-span-1">{children}</div>
    </div>
  )
}

export default TitledImageLink
